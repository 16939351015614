<template>
    <div class="preloader" v-if="isLoading">
        <!-- <img src="/img/backoffice/preloader.gif" /> -->
    </div>
    <div class="stats" v-else>
        <div class="wrap">
            <div class="left">
                <div class="logo">
                    <div v-html="game.game.logo"></div>
                </div>
                <div v-if="game" v-bind="game" class="countries">
                    <div class="counrtry" v-for="team in game.teams">
                        <div class="name">
                            {{ langMessagesValues._countries[team.country.preset] }}
                        </div>
                        <div class="cities">
                            <div class="city" v-bind:class="{ destroyed  : city.destroyed }" v-for="city of team.country.cities">
                                <div class="name">
                                    <img v-if="city.ufo" class="city__ufo" src="/img/ufo2.svg" />
                                    {{ langMessagesValues._cities[city.preset] }}
                                </div>
                                <div class="percentage">
                                    {{ city.standard }}%
                                </div>
                                <div class="cross" v-if="city.destroyed">
                                    ×
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="round__wrap">
                    <div class="number">
                        {{ game.game.round }}
                    </div>
                    <div class="word">
                        {{ langMessagesValues.round }}
                    </div>
                    <div class="additional-logo" v-if="game.game.partner">
                        <div v-html="game.game.partner"></div>
                    </div>
                </div>
                <div class="linechart">
                    <div class="label">
                        <div class="heading">
                            {{ langMessagesValues.world_ecology }}
                        </div>
                        <div class="percentage">
                            {{ game.game.ecology }}%
                        </div>
                    </div>
                    <line-chart :line-chart-data="lineChartData" ref="canvas" id="lineChart" :height="200"></line-chart>
                </div>
                <div class="barchart">
                    <div class="label">
                        {{ langMessagesValues.stats_standard }}
                    </div>
                    <bar-chart :bar-chart-data="barChartData" ref="canvas2" id="barChart" :height="350"></bar-chart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CommonMixin from '../mixins/commonMixin';
    import TableMixin from '../mixins/tableMixin';
    import Echo from 'laravel-echo'
    import ChartDataLabels from 'chartjs-plugin-datalabels';

    window.Pusher = require('pusher-js');

    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'ae8521110f07e1165742',
        cluster: 'eu',
        encrypted: false
    });

    import './../scss/public.scss';
    import lineChart from "../lineChart.js";
    import barChart from "../barChart.js";

    export default {
        name: 'Stats',
        components: {
            lineChart,
            barChart
        },
        mixins: [CommonMixin, TableMixin],
        data () {
            return {
                gameId: null,
                game: {},
                isLoading: true,
                langMessagesValues: {},

                barChartData: {
                    labels: [],
                    datasets: [
                        {
                            label: '',
                            backgroundColor: '',
                            borderColor: '#D2D2D2',
                            borderWidth: { top: 2, right: 0, bottom: 0, left: 0 },
                            data: []
                        }
                    ]
                },

                lineChartData: {
                    labels: [],
                    datasets: [{
                        label: '',
                        borderColor: '#69B33A',
                        pointBorderColor: '#69B33A',
                        pointBackgroundColor: '#69B33A',
                        pointHoverBackgroundColor: '#69B33A',
                        pointHoverBorderColor: '#69B33A',
                        pointBorderWidth: 5,
                        pointHoverRadius: 10,
                        pointHoverBorderWidth: 1,
                        pointRadius: 1,
                        fill: true,
                        backgroundColor: '',
                        borderWidth: 4,
                        data: []
                    }]
                }


            }
        },

        methods: {
            getStatsData() {
                let data = {
                    gameId: this.gameId,
                };
                let that = this;
                this.axios.post('/api/backoffice/host/games/stats', data)
                    .then((response) => {
                        this.game = response.data;
                        this.isLoading = false;

                        let labels = [];
                        let standard = [];
                        this.game.teams.forEach(team => {
                            labels.push(this.langMessagesValues._countries[team.country.preset]);
                            standard.push(team.country.averageStandard)
                        }, this);
                        this.barChartData.labels = labels;
                        this.barChartData.datasets[0].data = standard;
                        this.lineChartData.datasets[0].data = response.data.ecology;
                        this.lineChartData.labels = [];
                        for (let i = 0; i < response.data.ecology.length; i++) {
                            let roundValue = i + 1;
                            this.lineChartData.labels.push(roundValue);
                        }

                        this.$refs.canvas.render();
                        this.$refs.canvas2.render();

                    })
            },

            getLangMessagesValues: function (gameId) {
                let data = {
                    gameId: gameId,
                };
                this.axios.post('/api/game/get_lang_messages_values', data).then((response) => {
                    this.langMessagesValues = response.data;

                    this.getStatsData();
                })
            },

        },
        beforeCreate: function() {
            document.body.className = 'black';
        },
        mounted() {
            Chart.defaults.global.tooltips = false;
            this.gameId = this.$route.params.gameId;
            this.getLangMessagesValues(this.gameId);
            window.Echo.private(`endRound.${this.$route.params.gameId}`).listen(".round-ended", e => {
                this.getStatsData(false);
            });
        },
        beforeMount() {

        },
    };

</script>

