<template>
    <div class="final">
        <div class="wrap" v-if="!isLoading">
            <div class="logo">
                <div v-html="final.logo"></div>
            </div>
            <div class="additional-logo" v-if="final.partner"  v-html="final.partner"></div>
            <div class="heading">{{ langMessagesValues.final_title }}</div>
            <div class="nominations">

                <div class="nomination" v-if="final.investor">
                    <div class="title block">
                        <div class="icon">

                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.7656 31.7109H21.1641V36H14.7656V31.7109Z" fill="white"/>
                                <path d="M12.6562 30.6562C12.6562 30.0733 13.128 29.6016 13.7109 29.6016H22.2188C22.8017 29.6016 23.2734 30.0733 23.2734 30.6562V36H27.5625V9.5625C27.5625 8.98003 27.0903 8.50781 26.5078 8.50781H23.2734V5.34375C23.2734 4.76128 22.8012 4.28906 22.2188 4.28906H19.0547V1.05469C19.0547 0.471727 18.583 0 18 0C17.417 0 16.9453 0.471727 16.9453 1.05469V4.28906H13.7109C13.1285 4.28906 12.6562 4.76128 12.6562 5.34375V8.50781H9.49219C8.90972 8.50781 8.4375 8.98003 8.4375 9.5625V36H12.6562V30.6562ZM13.7109 14.8359H22.2188C22.8017 14.8359 23.2734 15.3077 23.2734 15.8906C23.2734 16.4736 22.8017 16.9453 22.2188 16.9453H13.7109C13.128 16.9453 12.6562 16.4736 12.6562 15.8906C12.6562 15.3077 13.128 14.8359 13.7109 14.8359ZM13.7109 19.0547H22.2188C22.8017 19.0547 23.2734 19.5264 23.2734 20.1094C23.2734 20.6923 22.8017 21.1641 22.2188 21.1641H13.7109C13.128 21.1641 12.6562 20.6923 12.6562 20.1094C12.6562 19.5264 13.128 19.0547 13.7109 19.0547ZM13.7109 23.2734H22.2188C22.8017 23.2734 23.2734 23.7452 23.2734 24.3281C23.2734 24.9111 22.8017 25.3828 22.2188 25.3828H13.7109C13.128 25.3828 12.6562 24.9111 12.6562 24.3281C12.6562 23.7452 13.128 23.2734 13.7109 23.2734Z" fill="white"/>
                                <path d="M1.05469 36H6.32812V16.9453H4.21875C3.63579 16.9453 3.16406 17.417 3.16406 18V21.1641H1.05469C0.471727 21.1641 0 21.6358 0 22.2188V34.9453C0 35.5283 0.471727 36 1.05469 36ZM2.10938 26.4375C2.10938 25.8545 2.5811 25.3828 3.16406 25.3828C3.74702 25.3828 4.21875 25.8545 4.21875 26.4375V30.6562C4.21875 31.2392 3.74702 31.7109 3.16406 31.7109C2.5811 31.7109 2.10938 31.2392 2.10938 30.6562V26.4375Z" fill="white"/>
                                <path d="M34.9453 21.1641H32.8359V18C32.8359 17.417 32.3642 16.9453 31.7812 16.9453H29.6719V36H34.9453C35.5283 36 36 35.5283 36 34.9453V22.2188C36 21.6358 35.5283 21.1641 34.9453 21.1641ZM33.8906 30.6562C33.8906 31.2392 33.4189 31.7109 32.8359 31.7109C32.253 31.7109 31.7812 31.2392 31.7812 30.6562V26.4375C31.7812 25.8545 32.253 25.3828 32.8359 25.3828C33.4189 25.3828 33.8906 25.8545 33.8906 26.4375V30.6562Z" fill="white"/>
                            </svg>

                        </div>
                        <div class="text">
                            <div class="name">
                                {{ langMessagesValues.final_investor }}
                            </div>
                            <div class="description">
                                {{ langMessagesValues.final_investor_info }}
                            </div>
                        </div>
                    </div>

                    <div class="number block">
                        {{ final.investor[0].value }} $
                    </div>

                    <div class="nominee block">
                        <div class="counrtry" v-for="investor in final.investor">
                            {{ langMessagesValues._countries[investor.preset] }}
                        </div>
                    </div>

                </div>

                <div class="nomination" v-for="ecologist in final.ecologist">
                    <div class="title block">
                        <div class="icon">
                            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.82973 18.4988C0.41218 18.5064 0.0759277 18.8473 0.0759277 19.2664V33.4653C0.0759277 33.6772 0.247847 33.8491 0.45973 33.8491H4.10156C4.44547 33.8491 4.74638 33.6218 4.84011 33.2917L8.23782 21.3192C8.45737 20.5453 8.04285 19.7316 7.28594 19.4569C5.89483 18.9502 3.76068 18.4496 0.82973 18.4988Z" fill="white"/>
                                <path d="M33.8535 26.9401C30.7828 26.9401 24.6413 30.826 21.5708 30.826C18.5001 30.826 15.0455 28.4754 15.0455 28.4754C15.0455 28.4754 19.1818 29.2432 21.5708 29.2432C23.9597 29.2432 24.6413 28.4279 24.6413 27.3239C24.6413 24.6371 20.0783 24.2194 17.7692 23.8678C15.5031 22.6058 14.3254 21.6079 10.5716 21.29C10.55 21.5096 10.5163 21.7275 10.4549 21.9441L7.37036 32.8143C10.8632 34.4033 16.8787 36.9199 20.0353 36.9199C24.6413 36.9199 36.9242 30.7785 36.9242 29.2432C36.9242 27.7078 35.3888 26.9401 33.8535 26.9401Z" fill="white"/>
                                <path d="M35.2645 1.11564C35.1324 0.685729 34.6872 0.363279 34.242 0.304961C31.5228 -0.0528262 27.7997 -0.542571 24.8211 2.13661C22.8782 3.88666 22.5898 6.80475 24.0287 8.91678C22.7585 10.2218 21.889 11.7724 21.3015 13.1838C21.0488 12.6962 20.7581 12.1926 20.4298 11.6736C20.0338 11.0479 19.6391 10.4901 19.3036 10.0404C19.5907 9.30268 19.7435 8.49266 19.7345 7.64079C19.7162 5.86587 18.9977 4.12485 17.8123 2.98558C14.1751 -0.508823 9.61819 -0.0513081 5.95794 0.317174L5.76759 0.337119C5.32077 0.381706 4.94007 0.681103 4.79417 1.10639C4.64827 1.53008 4.76035 2.00147 5.08439 2.31315C6.7241 3.88681 7.05883 5.56192 7.41192 7.33373C7.72664 8.90898 8.05212 10.5395 9.38174 11.8185C10.6392 13.0268 12.2989 13.6363 13.9218 13.6363C15.2984 13.6363 16.6405 13.1831 17.6905 12.2971C17.73 12.2448 17.7835 12.1953 17.8529 12.1497C18.9329 13.7066 20.1371 15.8416 20.1371 17.4453C20.1371 18.0823 20.6323 18.6097 21.2679 18.65C21.2938 18.6515 21.3195 18.6524 21.345 18.6524C21.9483 18.6524 22.4646 18.2037 22.5416 17.5974C22.5486 17.5427 23.1381 13.1778 25.8907 10.4725C25.9074 10.4829 25.9173 10.4888 25.9173 10.4888C26.4086 10.6931 26.9429 10.8281 27.5187 10.862C27.6277 10.8696 27.7368 10.8727 27.8442 10.8727C29.1845 10.8727 30.5171 10.383 31.4616 9.53241C32.5807 8.52518 32.8816 7.23704 33.1719 5.9919C33.4789 4.67754 33.7676 3.43703 35.0081 2.31937C35.3412 2.02149 35.398 1.54403 35.2645 1.11564Z" fill="white"/>
                            </svg>

                        </div>
                        <div class="text">
                            <div class="name">
                                {{ langMessagesValues.final_ecologist }}
                            </div>
                            <div class="description">
                                {{ langMessagesValues.final_ecologist_info }}
                            </div>
                        </div>
                    </div>

                    <div class="number block">
                        {{ ecologist.value }}
                    </div>

                    <div class="nominee block">
                        <div class="counrtry">
                            {{ langMessagesValues._countries[ecologist.preset] }}
                        </div>
                    </div>

                </div>

                <div class="nomination">
                    <div class="title block">
                        <div class="icon">
                            <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path d="M21.4358 13.0375C23.9183 12.6351 25.6045 10.2965 25.2021 7.814C24.7996 5.33155 22.461 3.64535 19.9785 4.04777C17.4961 4.4502 15.8099 6.78886 16.2123 9.27131C16.6147 11.7538 18.9534 13.44 21.4358 13.0375Z" fill="white"/>
                                    <path d="M37.2151 31.9351L26.5318 29.9667V18.9401H26.6087C27.0691 18.9401 27.4904 19.3197 27.495 19.7376V26.3082C27.495 28.0777 29.4772 29.1104 30.9267 28.1262L37.9709 23.3434C38.975 22.6617 39.2363 21.2952 38.5546 20.2912C37.8729 19.2873 36.5065 19.0258 35.5024 19.7076L31.8899 22.1604V19.7306C31.8899 19.7269 31.8899 19.7232 31.8899 19.7196C31.8756 16.8666 29.5064 14.5456 26.6087 14.5456C25.4368 14.5456 16.194 14.5456 14.8121 14.5456C11.9098 14.5456 9.53684 16.8666 9.52247 19.7196V19.7306V22.2228L6.17583 20.0114C5.16325 19.3424 3.80026 19.6209 3.13115 20.6334C2.46213 21.6459 2.74056 23.0091 3.75315 23.6781L10.5085 28.1417C11.9597 29.1005 13.9172 28.0658 13.9172 26.3084V19.7378C13.9219 19.3199 14.3472 18.9403 14.8121 18.9403H14.8654V29.8969C13.9088 30.1394 6.49977 32.0178 5.61254 32.2427C4.47757 32.5305 3.66823 33.5323 3.62543 34.7024C3.58272 35.8725 4.3167 36.9307 5.42765 37.3006L22.1418 42.8646C23.5231 43.3244 25.0164 42.5777 25.4765 41.1956C25.9364 39.8138 25.1892 38.3208 23.8075 37.8609L15.6383 35.1414C15.6677 35.1317 21.8309 34.5466 21.8309 34.5466L19.7848 35.1648L24.2141 36.6394C25.3999 37.0341 26.2706 37.9404 26.665 39.031L37.2063 37.1232C38.4594 36.8964 39.3713 35.8065 39.3735 34.5329C39.3758 33.2592 38.4675 32.1662 37.2151 31.9351Z" fill="white"/>
                                    <path d="M40.1134 6.50856L37.9177 4.68824C37.5926 4.80183 37.2474 4.86138 36.8928 4.86138C36.0725 4.86138 35.3008 4.5448 34.7172 3.9694C34.6401 3.89337 34.5732 3.80667 34.5293 3.70772C34.3795 3.37126 34.4599 2.99894 34.7013 2.75747L35.1036 2.35513C34.7919 2.09665 34.6558 1.69304 34.7123 1.29205C34.7267 1.18929 34.7274 1.08204 34.7121 0.97217C34.6412 0.461058 34.2167 0.0553314 33.7032 0.00551424C33.1117 -0.0519151 32.6028 0.345861 32.4835 0.889621L31.8311 1.25779C31.7435 1.30727 31.7657 1.43922 31.8647 1.45715L32.5488 1.58156C32.6007 1.70184 32.6727 1.81111 32.7607 1.90542C33.1812 2.35606 33.3954 2.96164 33.3954 3.57806C33.3954 5.51881 34.9686 7.09207 36.9094 7.09207H39.9028C40.2118 7.09215 40.3512 6.70563 40.1134 6.50856Z" fill="white"/>
                                    <path d="M36.893 4.01559C37.4697 4.01559 38.0464 3.79551 38.4863 3.35553L39.751 2.0909C39.311 1.65092 38.7342 1.43085 38.1576 1.43085C37.5808 1.43085 37.0042 1.65092 36.5642 2.0909L35.2996 3.35553C35.7395 3.79551 36.3162 4.01559 36.893 4.01559Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="43" height="43" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                        <div class="text">
                            <div class="name">
                                {{ langMessagesValues.final_peacemaker }}
                            </div>
                            <div class="description">
                                {{ langMessagesValues.final_peacemaker_info }}
                            </div>
                        </div>
                    </div>

                    <div class="number block">
                        {{ final.peacemaker[0].value }}
                    </div>

                    <div class="nominee block">
                        <div class="counrtry" v-for="peacemaker in final.peacemaker">
                            {{ langMessagesValues._countries[peacemaker.preset] }}
                        </div>
                    </div>

                </div>

                <div class="nomination" v-for="aggressor in final.aggressor">
                    <div class="title block">
                        <div class="icon">
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path d="M14.3733 25.683C14.1882 26.3766 13.921 27.0473 13.5787 27.6773C13.4782 27.8608 13.3755 28.0479 13.2722 28.2368C14.7416 27.5513 16.3566 27.1871 18.0021 27.1871C19.6473 27.1871 21.2623 27.5513 22.7312 28.2368C22.6293 28.0492 22.5274 27.8622 22.4282 27.6793C22.0835 27.0464 21.8152 26.3757 21.6287 25.683H14.3733Z" fill="white"/>
                                    <path d="M21.3758 19.3549C21.5755 17.877 22.2602 16.4765 23.3206 15.4141C23.3717 15.3628 23.4239 15.312 23.4767 15.2642C22.7966 15.4309 22.0957 15.516 21.389 15.516C20.2198 15.516 19.0667 15.2839 18 14.8395C16.9326 15.2848 15.7802 15.516 14.611 15.516C13.9034 15.516 13.2039 15.4309 12.5247 15.265C12.5768 15.3128 12.6288 15.3633 12.6793 15.4141C13.7581 16.4905 14.433 17.8652 14.6272 19.3549H21.3758Z" fill="white"/>
                                    <path d="M24.7029 22.519C24.7029 21.9364 24.2308 21.4643 23.6483 21.4643H12.3516C11.769 21.4643 11.2969 21.9364 11.2969 22.519C11.2969 23.1013 11.769 23.5737 12.3516 23.5737H23.6483C24.2308 23.5737 24.7029 23.1013 24.7029 22.519Z" fill="white"/>
                                    <path d="M32.6861 32.9548C31.9723 32.9548 31.2686 33.1391 30.6471 33.478C30.5479 33.3456 30.4397 33.2023 30.3263 33.0512C28.7915 31.0072 26.057 30.3601 23.8007 31.3967C20.4581 28.5968 15.5475 28.5968 12.2005 31.3959C9.94369 30.3555 7.21057 31.0036 5.67909 33.0504C5.5651 33.2023 5.45689 33.3465 5.35636 33.48C4.73399 33.1397 4.02895 32.9548 3.31374 32.9548C2.19506 32.9548 1.09973 33.4083 0.308715 34.1993C0.110961 34.3968 0 34.6648 0 34.9447V34.9452C0 35.5275 0.47241 35.9999 1.05468 35.9999H34.9452C35.5274 35.9999 35.9999 35.5275 35.9999 34.9447C35.9999 34.6648 35.8889 34.3968 35.6911 34.1993C34.9001 33.4083 33.8048 32.9548 32.6861 32.9548Z" fill="white"/>
                                    <path d="M4.44368 14.5369C5.55686 14.5369 6.61319 14.1255 7.42124 13.3922C7.55747 13.4023 7.69479 13.4073 7.83295 13.4073C9.00024 13.4073 10.1164 13.0483 11.0533 12.3833C12.1129 13.048 13.3483 13.4073 14.6109 13.4073C15.8183 13.4073 16.976 13.0914 17.9999 12.4883C19.0239 13.0914 20.1815 13.4073 21.3889 13.4073C22.6499 13.4073 23.8861 13.048 24.9493 12.3833C25.8829 13.0486 26.998 13.4073 28.1672 13.4073C28.3075 13.4073 28.4451 13.4023 28.5811 13.3922C29.3927 14.1255 30.4485 14.5369 31.5562 14.5369C34.0067 14.5369 36.0001 12.5435 36.0001 10.0933C36.0001 8.34918 35.0004 6.80121 33.4593 6.07557C33.1256 5.07691 32.509 4.18098 31.6855 3.51055C30.6951 2.70443 29.4457 2.26031 28.1672 2.26031C27.6308 2.26031 27.1067 2.33556 26.6005 2.48443C25.3338 0.91531 23.4425 0.000976562 21.3889 0.000976562C20.1815 0.000976562 19.0239 0.316832 17.9999 0.920254C16.976 0.316832 15.8183 0.000976562 14.6109 0.000976562C12.5606 0.000976562 10.671 0.915585 9.40481 2.4847C8.89779 2.33556 8.37182 2.26058 7.83295 2.26058C6.55661 2.26058 5.30885 2.70443 4.31898 3.51109C3.49639 4.18126 2.88033 5.07691 2.54662 6.07474C1.00195 6.79956 0 8.34808 0 10.0933C0 12.5435 1.99346 14.5369 4.44368 14.5369Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="36" height="36" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                        <div class="text">
                            <div class="name">
                                {{ langMessagesValues.final_aggressor }}
                            </div>
                            <div class="description">
                                {{ langMessagesValues.final_aggressor_info }}
                            </div>
                        </div>
                    </div>

                    <div class="number block">
                        {{ aggressor.value }}
                    </div>

                    <div class="nominee block">
                        <div class="counrtry">
                            {{ langMessagesValues._countries[aggressor.preset] }}
                        </div>
                    </div>

                </div>

                <div class="nomination" v-for="winner in final.winner">
                    <div class="title block">
                        <div class="icon">
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M34.9453 2.59827H30.3256C30.3423 2.08658 30.3517 1.57187 30.3517 1.05469C30.3517 0.472137 29.8792 0 29.297 0H6.70331C6.12076 0 5.64862 0.472137 5.64862 1.05469C5.64862 1.57187 5.65768 2.08658 5.67444 2.59827H1.05469C0.472137 2.59827 0 3.0704 0 3.65295C0 8.37872 1.23514 12.8372 3.47772 16.2076C5.69449 19.5395 8.64734 21.4505 11.8369 21.6365C12.5601 22.4234 13.3248 23.065 14.121 23.5544V28.242H12.3517C10.2126 28.242 8.47266 29.9822 8.47266 32.121V33.8904H8.39767C7.81512 33.8904 7.34299 34.3628 7.34299 34.945C7.34299 35.5276 7.81512 35.9997 8.39767 35.9997H27.6023C28.1849 35.9997 28.657 35.5276 28.657 34.945C28.657 34.3628 28.1849 33.8904 27.6023 33.8904H27.5273V32.121C27.5273 29.9822 25.7871 28.242 23.6483 28.242H21.879V23.5544C22.6752 23.065 23.4396 22.4234 24.1628 21.6365C27.3527 21.4505 30.3052 19.5395 32.5223 16.2076C34.7649 12.8372 36 8.37872 36 3.65295C36 3.0704 35.5276 2.59827 34.9453 2.59827ZM5.23389 15.0392C3.38406 12.2591 2.29724 8.62344 2.13162 4.70764H5.79227C6.17267 9.5213 7.30261 13.9705 9.06866 17.5026C9.34991 18.0651 9.64407 18.5963 9.94922 19.097C8.19498 18.4345 6.57147 17.0497 5.23389 15.0392ZM30.7661 15.0392C29.4285 17.0497 27.805 18.4345 26.0508 19.097C26.3562 18.5963 26.6501 18.0651 26.9313 17.5026C28.6974 13.9705 29.8271 9.5213 30.2077 4.70764H33.8684C33.7028 8.62344 32.6159 12.2591 30.7661 15.0392Z" fill="white"/>
                            </svg>
                        </div>
                        <div class="text">
                            <div class="name">
                                {{ langMessagesValues.final_winner }}
                            </div>
                            <div class="description">
                                {{ langMessagesValues.final_winner_info }}
                            </div>
                        </div>
                    </div>

                    <div class="number block">
                        {{ winner.value }} %
                    </div>

                    <div class="nominee block">
                        <div class="counrtry">
                            {{ langMessagesValues._countries[winner.preset] }}
                        </div>
                    </div>
                    <div class="overlay">

                    </div>
                    <div class="winner">
                        {{ langMessagesValues.final_winner_winner }} !
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import './../scss/public.scss';

    export default {
        name: 'Final',
        data () {
            return {
                gameId: null,
                final: null,
                isLoading: true,
                langMessagesValues: {},
            }
        },

        methods: {

            getFinalData() {
                let data = {
                    gameId: this.gameId,
                };
                let that = this;
                this.axios.post('/api/backoffice/host/games/final', data)
                    .then((response) => {
                        this.final = response.data;
                        this.isLoading = false;
                    })
            },

            getLangMessagesValues: function (gameId) {
                let data = {
                    gameId: gameId,
                };
                this.axios.post('/api/game/get_lang_messages_values', data).then((response) => {
                    this.langMessagesValues = response.data;

                    this.getFinalData();
                })
            },

        },
        beforeCreate: function() {
            document.body.className = 'black';
        },
        beforeMount() {
            this.gameId = this.$route.params.gameId;
            this.getLangMessagesValues(this.gameId);
        },
    }
</script>

